img.fluid {
  max-width: 100%;
}

img.block {
  display: block;
}

img.circle {
  border-radius: 1000px;
}
