.video-react .video-react-control-bar {
  display: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3em;
  font-family: $brand-face;

  @include background-color-with-alpha(
    $video-react-primary-background-color,
    $video-react-primary-background-transparency
  );
}

.video__hide-default-fullscreen-button .video-react-icon-fullscreen {
  display: none;
}

// Video has started playing
.video-react-has-started .video-react-control-bar {
  @include display-flex;
  visibility: visible;
  opacity: 1;

  $trans: visibility 0.1s, opacity 0.1s; // Var needed because of comma
  @include transition($trans);
}

// Video has started playing AND user is inactive
.video-react-has-started.video-react-user-inactive.video-react-playing {
  .video-react-control-bar.video-react-control-bar-auto-hide {
    // Remain visible for screen reader and keyboard users
    visibility: visible;
    opacity: 0;

    $trans: visibility 1s, opacity 1s;
    @include transition($trans);
  }
}

.video-react-controls-disabled .video-react-control-bar,
.video-react-using-native-controls .video-react-control-bar,
.video-react-error .video-react-control-bar {
  // !important is ok in this context.
  display: none !important;
}

// Don't hide the control bar if it's audio
.video-react-audio.video-react-has-started.video-react-user-inactive.video-react-playing
  .video-react-control-bar {
  opacity: 1;
  visibility: visible;
}

// IE 8 + 9 Support
.video-react-has-started.video-react-no-flex .video-react-control-bar {
  display: table;
}
