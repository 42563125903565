$brand-face: 'Inter', 'Helvetica Neue', Arial, 'Lucida Grande', sans-serif;

$base-font-size: 16px;
$base-line-height: $base-font-size * 1.5;
$lineheight-s: 1;
$lineheight-m: 1.2;
$lineheight-l: 1.5;
$lineheight-xl: 1.75;

$lineheight-tight: $lineheight-s;
$lineheight-close: $lineheight-m;
$lineheight-regular: $lineheight-l;
$lineheight-spaced: $lineheight-xl;

$heading-size-1: 55px !default;
$heading-size-2: 48px !default;
$heading-size-3: 32px !default;
$heading-size-4: 24px !default;
$heading-size-5: 20px !default;
$heading-size-6: 18px !default;

$heading-size-1--palm: 40px !default;
$heading-size-2--palm: 32px !default;
$heading-size-3--palm: 24px !default;
$heading-size-4--palm: 20px !default;
$heading-size-5--palm: 18px !default;
$heading-size-6--palm: 16px !default;

$heading-weight-1: 700 !default;
$heading-weight-2: 600 !default;
$heading-weight-3: 600 !default;
$heading-weight-4: 600 !default;
$heading-weight-5: 600 !default;
$heading-weight-6: 600 !default;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$base-font-weight: $font-weight-regular;

$xx-large-font-size: $base-font-size + 8;
$x-large-font-size: $base-font-size + 4;
$large-font-size: $base-font-size + 2;
$small-font-size: $base-font-size - 2;
$mini-font-size: $base-font-size - 4;
