$video-react-primary-background-color: $light-link-color;
$video-react-primary-background-transparency: 1;
$video-react-secondary-background-color: $link-color;
$video-react-secondary-background-transparency: 1;
$video-react-big-play-button-width: 68px;
$video-react-big-play-button-height: 68px;

.videoWrapper {
  align-self: center;
  width: 100%;
  height: 100%;
  .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

.video-react .video__play {
  background: transparent;
  border: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  outline: none;
  opacity: 1;
  transition: opacity 0.26s ease-in-out;
}

.video-react-playing .video__play {
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.video-container .progress-bar {
  margin: -4px auto 0;
  background: transparent;
  position: relative;
  z-index: 10;
  transition: opacity 0.26s ease-in-out;
}

.video-container .video-react-playing .progress-bar {
  opacity: 0;
}

.video-container:hover .video-react-playing .progress-bar {
  opacity: 1;
}

.learning-lander-video .video__play {
  position: absolute;
  z-index: 10;
  top: auto;
  bottom: 20px;
  left: 20px;
  transform: none;
}
.learning-lander-video .video-react-has-started .video__play {
  display: none;
}
