.spinner {
  animation: icon-spinner-spin 1.7s infinite linear;

  // Modifier
  &.light {
    fill: $color-wireframe-grey-40;
  }
  &.dark {
    fill: rgba(255, 255, 255, 0.6);
  }
}

.spinner--centered {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: icon-spinner-spin--centered 1.7s infinite linear;
}

@keyframes icon-spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes :global(icon-spinner-spin) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes icon-spinner-spin--centered {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes :global(icon-spinner-spin--centered) {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}
