@use 'sass:math';

// @tailwind base;
// @tailwind components;
// @tailwind utilities;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

$footer-height: 74px;
$off-canvas-offset: -75%;

/* Settings */
@import 'variables';

/* Tools */
@import 'tools.responsive';
@import 'tools.html5-input-types';
@import 'tools.mixins';
@import 'tools.hide';

@layer base {
  @import 'base.page';
  @import 'base.template';
  @import 'base.links';
  @import 'base.forms';
}

@layer components {
  @import 'objects.list-inline';
  @import 'objects.buttons';
  @import 'objects.images';

  @import 'components.site-header';
  @import 'components.site-footer';
  @import 'components.cookie-consent';
  @import 'components.homepage';

  @import 'components.segment';

  @import 'components.auth';
  @import 'components.social-signin';

  @import 'components.learning-page';

  @import 'components.badge';
  @import 'components.user-menu';
  @import 'components.video';
}

@import 'components.card';
@import 'objects.activity-indicator';
@import 'components.react-autosuggest';

@layer utilities {
  @import 'trumps.hide';
  @import 'trumps.fix-webkit-autofill-color';
}

@import './video-react/video-react';
