$form-field-border-color: transparent;
$form-input-background-color: #fff;
$form-input-text-color: $base-text-color;
$auth-form-input-background-color: $color-text-grey-10;
$auth-form-input-text-color: $color-core-brand-first-blue-60;
$form-border-color: $color-text-grey-10;
$form-border-color-hover: $color-text-grey-40;
$form-border-color-focus: $color-text-grey-40;
$form-border-color-error: $color-status-error-light;
$form-border-radius: 4px;
$auth-form-border-radius: 20px;
$form-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
$form-box-shadow-focus: none;
$form-font-size: $small-font-size;
$form-font-family: $brand-face;
$auth-form-font-size: $base-font-size;
