.large-header {
  display: flex;
  height: 200px;
  position: relative;
}

.large-header__content-container {
  background-color: $color-core-brand-first-blue-100;
  color: #fff;
  flex-grow: 2;
}

.large-header__content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

@include media-query(palm-small) {
  .large-header__content h1 {
    max-width: 8em;
  }
}

.social-signin-container {
  margin-bottom: 40px;
  width: 440px;

  p {
    margin-bottom: 1rem;
  }

  img {
    display: inline-block;
  }
}

@include media-query(portable) {
  .social-signin-container {
    width: 316px;
  }
}

.auth-title {
  font-size: $heading-size-2;
  margin-bottom: 0;
}
.login__forget {
  font-size: $mini-font-size;
  font-weight: $font-weight-regular;
  color: #fff;
}

@include media-query(palm) {
  .auth-title {
    font-size: $heading-size-2--palm;
    max-width: 8em;
  }
}
