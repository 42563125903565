@mixin block-menu() {
}

@mixin block-menu--light() {
  @include block-menu();
  background-color: $content-background-color;
}

@mixin block-menu__item() {
  display: block;
  width: 100%;
}

@mixin block-menu--light__item() {
  @include block-menu__item();
  color: $link-color;
}

@mixin block-menu--light__item--hover() {
  background-color: $color-wireframe-grey-10;
  text-decoration: none;
}

// The group-starter and util-border elements are required to create a border to divide the menu link groups,
// with the border being narrower than the menu anchor element.
@mixin block-menu__item--group-starter($vertical-spacing) {
  position: relative;
  width: 100%;
  padding-top: $vertical-spacing;
  margin-top: $vertical-spacing;
}

@mixin block-menu__util-border($right, $left) {
  position: absolute;
  top: 0;
  right: $right;
  bottom: 100%;
  left: $left;
  border-top: 1px solid $color-wireframe-grey-20;
}

@mixin line-clamp(
  $lines-to-show,
  $font-size: $base-font-size,
  $line-height: $base-line-height
) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: $lines-to-show * $line-height; /* Fallback for non-webkit */
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Placeholder
@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
}

@mixin ellipsis($max-width) {
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
