.video-react .video-react-play-control {
  cursor: pointer;
  @include flex(none);
  @extend .video-react-icon;
  @extend .video-react-icon-play-arrow;

  &.video-react-playing {
    @extend .video-react-icon-pause;
  }
}
