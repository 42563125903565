.site-pre-footer {
  background-color: $color-old-brand-dark-blue;
  p {
    color: #fff;
    margin: 0;
    @apply tw-px-0 tw-py-4;
  }
  a {
    color: $color-core-brand-supporting-light-blue-40;
  }
  a:not(.btn):hover {
    color: #fff;
  }
}

.site-footer {
  background-color: $color-core-brand-first-blue-100;
  border-top: 1px solid $color-text-grey-40;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  a {
    color: $color-core-brand-supporting-light-blue-60;
  }
  h1 {
    margin: 0;
  }

  img {
    display: inline-block;
  }
}

.site-footer__logo-link {
  height: 47px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}

.site-footer-menu__list {
  border-bottom: 1px solid $color-core-brand-supporting-light-blue-60;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;
  a {
    margin-right: 1em;
  }
}

.site-footer-menu__label {
  font-weight: $font-weight-medium;
  width: 8em;
  a {
    margin-right: 0;
  }
}

@include media-query(palm) {
  .site-footer {
    padding-top: 0;
  }
  .site-footer-menu__label {
    width: 100%;
  }
  .site-footer-menu__list--pages {
    display: block;
  }
}

.site-footer__get-apps {
  font-size: $small-font-size;
  font-weight: $font-weight-medium;
  padding: 15px 0 25px;
  margin: 0;
  display: block;
  & > span a {
    margin-right: 1rem;
  }
  .app-download-links {
    display: block;
    margin: 10px 0 0;
  }
}

@include media-query(palm-small) {
  .site-footer__get-apps__platforms {
    display: block;
  }
}

.site-footer__copyright {
  font-weight: $font-weight-light;
  padding: 0;
  margin: 0;
}

.site-footer-menu__list--legal {
  border: 0;
}
