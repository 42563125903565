.cc-window {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 24em;
  z-index: 100;
  padding: 15px 20px 20px;
  max-width: 100%;
  background-color: #eee;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  color: black;
}

.cc-window__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  button {
    white-space: nowrap;
  }
  p {
    flex-grow: 1;
    margin: 0 20px 0 0;
  }
  a {
    color: $color-core-brand-second-blue-60;
  }
}

@include media-query(palm) {
  .cc-window {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
