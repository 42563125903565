@use 'sass:math';

$card-background-color: #fff;
$card-width: 220px;
$card-width-in-learning: 180px;
$card-h-spacing-unit: 50px;
$card-v-spacing-unit: math.div($card-h-spacing-unit, 2);
$card-title-z-index: 9;

$mobile-card-width: 300px;

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@include media-query(not-palm-small) {
  .cards--n2,
  .cards--n3 {
    justify-content: flex-start;
    .card {
      margin-right: 1em;
    }
    .card:last-child {
      margin-right: 0;
    }
  }
}

.card {
  flex: 0 1 32%;

  display: flex;
  flex-direction: column;
}

@include media-query(palm-small) {
  .card {
    flex: 0 1 49%;
  }
}

@include media-query(palm-smallest) {
  .card {
    flex: 0 1 100%;
  }
}

@include media-query(desk) {
  .card {
    flex: 0 1 24%;
  }
}

@include media-query(large-desk) {
  .card {
    flex: 0 1 19%;
  }
}

.card {
  background-color: #fff;
  margin-bottom: 1em;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.learning-card {
  justify-content: space-between;
}

.learning-card__resource {
  height: 132px;
  position: relative;

  display: block;
  background-position: 50% 50%;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.learning-card__label {
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.75rem;
  padding: 0.25rem 0.75rem;
  background-color: #3178c4;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  top: 0.5rem;
  left: 0;
  position: absolute;
}

.learning-card__label--accreditor {
  top: 3.2rem;
  background-color: #58368a;
}

.learning-card__title-section {
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  display: flex;
  width: 100%;
  background: #09336a;
}

.learning-card__title {
  color: #fff;
  display: block;
  width: 100%;
  height: 60px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  word-wrap: break-word;
  text-align: left;

  overflow: hidden;

  &:hover {
    text-decoration: none;
  }

  span {
    max-height: 100%;
    @include line-clamp(3, 16px, 19.2px);
    text-overflow: ellipsis;
  }
}

.learning-card__meta,
.learning-card__footer {
  font-size: $small-font-size;
  padding: 5px 10px;
  line-height: $small-font-size * $lineheight-close;
}

.learning-card__meta-row {
  @include line-clamp(
    2,
    $small-font-size,
    $small-font-size * $lineheight-close
  );
  margin: 5px 0;
}

.learning-card__meta-row--series-sub {
  display: flex;
  justify-content: space-between;
}

.learning-card__footer-meta {
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.55);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 5px;
}
.learning-card__footer-meta__right {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.learning-card__buttons {
  display: flex;
  padding: 10px 0;
  font-size: $small-font-size;
  line-height: $small-font-size * $lineheight-close;
  .btn,
  form {
    margin-right: 5px;
    width: 100%;
  }
  .btn:last-child,
  form:last-child {
    margin-right: 0;
  }
}

.price-label-container {
  position: absolute;
  z-index: $card-title-z-index;
  bottom: 0.5rem;
  right: 0;
  min-width: 47px;
  height: 29px;
  background-color: gold;
  border: 2px solid white;
  border-right: 0;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  white-space: nowrap;
}

.price-label-text {
  color: black;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}
