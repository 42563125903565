@use 'sass:math';
.video-react {
  .video-react-big-play-button {
    font-size: 3em;
    line-height: $video-react-big-play-button-height;
    height: $video-react-big-play-button-height;
    width: $video-react-big-play-button-width; // Firefox bug: For some reason without width the icon wouldn't show up. Switched to using width and removed padding.
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid $video-react-primary-foreground-color;
    // Need a slightly gray bg so it can be seen on black backgrounds
    @include background-color-with-alpha(
      $video-react-primary-background-color,
      $video-react-primary-background-transparency
    );
    @include border-radius(0.3em);
    @include transition(all 0.4s);
    @extend .video-react-icon;
    @extend .video-react-icon-play-arrow;
    // Since the big play button doesn't inherit from vjs-control, we need to specify a bit more than
    // other buttons for the icon.
    &:before {
      @extend %video-react-icon-default;
    }
    // Allow people that hate their poster image to center the big play button.
    &.video-react-big-play-button-center {
      top: 50%;
      left: 50%;
      margin-top: math.div(-$video-react-big-play-button-height, 2);
      margin-left: math.div(-$video-react-big-play-button-width, 2);
    }
    &.big-play-button-hide {
      display: none;
    }
  }
  &:hover .video-react-big-play-button,
  .video-react-big-play-button:focus {
    outline: 0;
    border-color: $video-react-primary-foreground-color;
    @include background-color-with-alpha(
      $video-react-secondary-background-color,
      $video-react-secondary-background-transparency
    );
    @include transition(all 0s);
  }
}
