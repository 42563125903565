.site-header {
  @apply tw-bg-ms-blue-old-400 tw-text-white;
  img {
    @apply tw-inline-block;
  }
}

.site-header a {
  @apply tw-text-ms-blue-light-40;
}

.site-header a.active {
  @apply tw-text-white;
}

.site-header a.btn--fill.btn--secondary,
.site-header a.active.btn--fill.btn--secondary {
  @apply tw-text-black;
}

.site-header h1 {
  @apply tw-m-0 tw-pt-2 tw-pb-2 tw-text-[18px];
}

.site-header__logo-link {
  @apply tw-h-[47px] tw-flex tw-flex-wrap tw-flex-row tw-items-center;
}

.site-header__logo-link svg {
  @apply tw-mr-2;
}

.site-header__section-title {
  @apply tw-bg-ms-blue-old-950;
}

.site-header__section-title h2 {
  @apply tw-pt-4 tw-pb-4 tw-text-[18px];
}

.site-header__section-title h2 a {
  @apply tw-text-white;
}
